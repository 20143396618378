export const QUERY_CONSTANTS = {
  /** ======== Dashboard ======== */
  SHIPMENTS_IN_TRANSIT: "SHIPMENTS_IN_TRANSIT",
  SHIPMENTS_IN_TRANSIT_COUNT: "SHIPMENTS_IN_TRANSIT_COUNT",
  DASHBOARD_COUNTS: "DASHBOARD_COUNTS",
  DASHBOARD_SIBLING: "DASHBOARD_SIBLING",
  DASHBOARD_WORKLOAD: "DASHBOARD_WORKLOAD",

  /** ======== Workspace (Shipments) ======== */
  SHIPMENT_SERVICE_INFO: "SHIPMENT_SERVICE_INFO",
  SHIPMENT_FILE: "SHIPMENT_FILE",
  SHIPMENT_COUNT: "SHIPMENT_COUNT",
  SHIPMENT_ALERTS: "SHIPMENT_ALERTS",
  SHIPMENT_RECOMMENDATIONS: "SHIPMENT_RECOMMENDATIONS",
  SHIPMENTS_LIST: "SHIPMENTS_LIST",
  SHIPMENTS_LIST_NEW: "SHIPMENTS_LIST_NEW",
  SHIPMENT_DOC_TYPES: "SHIPMENT_DOC_TYPES",
  SHIPMENT_LOAD_PLAN: "SHIPMENT_LOAD_PLAN",
  SHIPMENT_LOAD_PLAN_CALCULATE: "SHIPMENT_LOAD_PLAN_CALCULATE",
  SHIPMENT_INSTRUCTIONS: "SHIPMENT_INSTRUCTIONS",
  SHARE_SHIPMENT_DETAILS: "SHARE_SHIPMENT_DETAILS",
  CONSOLIDATE_SHIPMENTS: "CONSOLIDATE_SHIPMENTS",
  CONSOLIDATED_SHIPMENT_DETAIL: "CONSOLIDATED_SHIPMENT_DETAIL",
  SHIPMENTS_SEARCH: "SHIPMENTS_SEARCH",

  /** ======== Special Rules ======== */
  SPECIAL_RULE_IMPACTS: "SPECIAL_RULE_IMPACTS",
  SPECIAL_RULE_DESTINATIONS: "SPECIAL_RULE_DESTINATIONS",
  LATEST_POLICIES: "LATEST_POLICIES",
  SPECIAL_RULES: "SPECIAL_RULES",

  /** ======== Trade Lanes ======== */
  TRADE_LANE_COUNTRIES: "TRADE_LANE_COUNTRIES",
  DUTY_TAXES: "DUTY_TAXES",
  FIND_HS_CODES: "FIND_HS_CODES",
  PUBLIC_TRADE_LANE_COUNT: "PUBLIC_TRADE_LANE_COUNT",
  PRIVATE_TRADE_LANE_COUNT: "PRIVATE_TRADE_LANE_COUNT",
  PRIVATE_TRADE_LANES: "PRIVATE_TRADE_LANES",
  PRIVATE_TRADE_LANE: "PRIVATE_TRADE_LANE",
  TRADE_LANE_STEPS: "TRADE_LANE_STEPS",
  TRADE_LANE_PROJECTS: "TRADE_LANE_PROJECTS",
  TRADE_LANE_DEF: "TRADE_LANE_DEF",
  TRADE_LANE_DEF_TRANSPORT_MODES: "TRADE_LANE_DEF_TRANSPORT_MODES",
  USER_FORWARDERS: "USER_FORWARDERS",
  USER_PROVIDERS: "USER_PROVIDERS",
  TRADELANE_RULES: "TRADELANE_RULES",
  TRADELANE_RULE_OPTIONS: "TRADELANE_RULE_OPTIONS",
  TRADELANE_CONFIG: "TRADELANE_CONFIG",
  MASTER_TRADELANE_CONFIG: "MASTER_TRADELANE_CONFIG",
  PURCHASE_TRADE_LANE_CONFIG: "PURCHASE_TRADE_LANE_CONFIG",

  /** ======== Trade Policies ======== */
  TRADE_POLICY_COUNT: "TRADE_POLICY_COUNT",

  /** ======== Tasks ======== */
  TASK_COUNT: "TASK_COUNT",
  ALL_TASKS: "ALL_TASKS",

  /** ======== Nylas ======== */
  NYLAS_MAILS: "NYLAS_MAILS",
  NYLAS_MAILS_FILES: "NYLAS_MAILS_FILES",
  NYLAS_ACTIVE: "NYLAS_ACTIVE",

  /** ======== Others ======== */
  COUNTRIES: "COUNTRIES",
  CUSTOMERS: "CUSTOMERS",
  CUSTOMER_USERS: "CUSTOMER_USERS",
  USER_ASSIGNED_COUNTRIES: "USER_ASSIGNED_COUNTRIES",
  USER_ASSIGNED_TRANSPORTATION_MODES: "USER_ASSIGNED_TRANSPORTATION_MODES",
  PORT_CONGESTION: "PORT_CONGESTION",
  INCOTERMS: "INCOTERMS",
  SHARED_SHIPMENT: "SHARED_SHIPMENT",

  /** ======== Users ======== */
  USERS: "USERS",
  USER_INFO: "USER_INFO",
  CURRENT_USER_INFO: "CURRENT_USER_INFO",
  USER_TENANTS: "USER_TENANTS",
  USER_SIGNATURE: "USER_SIGNATURE",
  ALL_USER_ROLES: "USER_ROLES",
  ALL_USER_PERMISSIONS: "USER_PERMISSIONS",
  USER_TABS: "USER_TABS",
  USERS_WITH_INBOX_CONNECTED: "USERS_WITH_INBOX_CONNECTED",

  /** ======== Performance Dashboard ======== */
  TOP_10_CARRIERS: "TOP_10_CARRIERS",
  DOCUMENT_ERROR_PERCENTAGE: "DOCUMENT_ERROR_PERCENTAGE",
  ANNUAL_DND_DATA: "ANNUAL_DND_DATA",
  ANNUAL_ORDERS_COUNT: "ANNUAL_ORDERS_COUNT",
  IN_QUEUE_DOCUMENTS: "IN_QUEUE_DOCUMENTS",
  TRADE_LANE_ORDERS: "TRADE_LANE_ORDERS",
  CARRIER_TRANSIT_TIME: "CARRIER_TRANSIT_TIME",
  ANALYTICS_LAST_UPDATED: "ANALYTICS_LAST_UPDATED",
  BOOK_TRANSPORTATION_PREREQUISITES: "BOOK_TRANSPORTATION_PREREQUISITES",

  /** ======== Settings ======== */
  BILLING_DETAILS: "BILLING_DETAILS",
  COMPANY_DATA: "COMPANY_DATA",
  DEMURRAGE_DETEMTION: "DEMURRAGE_DETEMTION",
  LINER_OPTIONS: "LINER_OPTIONS",
  PORT_OPTIONS: "PORT_OPTIONS",
  LINESCAPE_PORT_OPTIONS: "LINESCAPE_PORT_OPTIONS",
  DEMURRAGE_DETEMTION_INDIVIDUAL: "DEMURRAGE_DETEMTION_INDIVIDUAL",
  DEMURRAGE_DETEMTION_SALES: "DEMURRAGE_DETEMTION_SALES",
  ALL_DOCUMENT_TYPES: "ALL_DOCUMENT_TYPES",
  CUSTOMER_DOCUMENT_TEMPLATES: "CUSTOMER_DOCUMENT_TEMPLATES",
  CUSTOMER_DOCUMENT_TEMPLATE_FILE: "CUSTOMER_DOCUMENT_TEMPLATE_FILE",
  CUSTOMER_FILE_BLOB: "CUSTOMER_FILE_BLOB",
  MATERIAL_DATA: "Material_DATA",
  MATERIAL_BY_ID: "MATERIAL_BY_ID",
  MATERIAL_CATEGORY: "MATERIAL_CATEGORY",
  MATERIAL_DOCUMENT_TYPES: "MATERIAL_DOCUMENT_TYPES",
  MATERIAL_DOCUMENT: "MATERIAL_DOCUMENT",
  CUSTOMER_PURCHASE_TEMPLATE: "CUSTOMER_PURCHASE_TEMPLATE",

  /** ======== Load Plan ======== */
  ALL_ITEMS: "ALL_ITEMS",
  SKU: "SKU",
  SKU_BY_ID: "SKU_BY_ID",
  SKU_PPOD: "SKU_PPOD",

  /** ======== Delivery ======== */
  DELIVERY_DETAILS: "DELIVERY_DETAILS",
  DELIVERY_TRADE_POLICIES: "DELIVERY_TRADE_POLICIES",
  DELIVERY_PRODUCTS: "DELIVERY_PRODUCTS",
  DELIVERY_TRACKING_DETAILS: "DELIVERY_TRACKING_DETAILS",
  ALL_COURIERS: "ALL_COURIERS",
  DELIVERY_EQUIPMENT_LOADPLAN: "DELIVERY_EQUIPMENT_LOADPLAN",
  DELIVERY_PELLET_LOADPLAN: "DELIVERY_PELLET_LOADPLAN",
  DELIVERY_MODES_OF_TRANSPORTATION: "DELIVERY_MODES_OF_TRANSPORTATION",
  DELIVERY_LISTING: "DELIVERY_LISTING",
  DELIVERY_LISTING_PAGINATE: "DELIVERY_LISTING_PAGINATE",
  DISPATCHED_DELIVERY_LISTING: "DISPATCHED_DELIVERY_LISTING",
  DISPATCHED_DELIVERY_LISTING_PAGINATE: "DISPATCHED_DELIVERY_LISTING_PAGINATE",
  DELIVERY_NYLAS_MAILS: "DELIVERY_NYLAS_MAILS",
  DELIVERY_COMPLETION: "DELIVERY_COMPLETION",
  SHARED_DELIVERY: "SHARED_DELIVERY",
  SHARED_DELIVERY_FORM: "SHARED_DELIVERY_FORM",
  DELIVERY_TASKS: "DELIVERY_TASKS",
  CONSOLIDATE_DELIVERY_RECOMMENDATIONS: "CONSOLIDATE_DELIVERY_RECOMMENDATIONS",
  DELIVERY_LIST_NEW: "DELIVERY_LIST_NEW",
  DELIVERY_CUSTOM_DOC_NAMES: "DELIVERY_CUSTOM_DOC_NAMES",
  BROADCAST_CONFIGURATION: "BROADCAST_CONFIGURATION",
  LATEST_PICKUP_DATE: "LATEST_PICKUP_DATE",

  /** ======== Warehouse ======== */
  WAREHOUSE_DETAILS: "WAREHOUSE_DETAILS",

  /** ======== Orders ======== */
  ORDER_OPTIONS: "ORDER_OPTIONS",

  /** ======== Sailing schedule ======== */
  SAILING_PORTS: "SAILING_PORTS",

  /** ======== Document Types ======== */
  DOCUMENT_TYPES: "DOCUMENT_TYPES",
  DOCUMENT_TYPES_NEW: "DOCUMENT_TYPES_NEW",
  DOCUMENT_TYPES_FOR_SHARE: "DOCUMENT_TYPES_FOR_SHARE",

  /** ======== Control tower ======== */
  CONTROL_TOWER: "CONTROL_TOWER",

  /** ======== Control tower ======== */
  PERFORMANCE_DASHBOARD: "PERFORMANCE_DASHBOARD",

  /** ======== Logistics providers ======== */
  LOGISTICS_PROVIDERS: "LOGISTICS_PROVIDERS",
  LOGISTICS_PROVIDER: "LOGISTICS_PROVIDER",
  LOGISTICS_DASHBOARD: "LOGISTICS_DASHBOARD",

  /** ======== Logistics purchase providers ======== */
  LOGISTICS_PROVIDERS_PURCHASE: "LOGISTICS_PROVIDERS_PURCHASE",
  LOGISTICS_PROVIDER_PURCHASE: "LOGISTICS_PROVIDER_PURCHASE",
  LOGISTICS_DASHBOARD_PURCHASE: "LOGISTICS_DASHBOARD_PURCHASE",

  /** ======== Customer ======== */
  CUSTOM_DOC_NAMES: "CUSTOM_DOC_NAMES",

  /** ======== Purchase Order ======== */
  PURCHASE_ORDERS_LIST_NEW: "PURCHASE_ORDERS_LIST_NEW",
  PURCHASE_ORDER_DETAILS: "PURCHASE_ORDER_DETAILS",
  PURCHASE_ORDER_OPTIONS: "PURCHASE_ORDER_OPTIONS",
  PURCHASE_ORDER_SUPPLIER_NAMES: "PURCHASE_ORDER_SUPPLIER_NAMES",
  PURCHASE_ORDER_DELIVERIES: "PURCHASE_ORDER_DELIVERIES",
  PURCHASE_DELIVERY_TIMELINE: "PURCHASE_DELIVERY_TIMELINE",
  PURCHASE_DELIVERY_CURRENT: "PURCHASE_DELIVERY_CURRENT",
  PURCHASE_DELIVERY_QUALITY_CHECK: "PURCHASE_DELIVERY_QUALITY_CHECK",
  PURCHASE_LOAD_PLAN: "PURCHASE_LOAD_PLAN",
  PURCHASE_ORDER_EMAILS: "PURCHASE_ORDER_EMAILS",
  PURCHASE_ORDER_COMMUNICATION_SUMMARY: "PURCHASE_ORDER_COMMUNICATION_SUMMARY",
  HISTORIC_LEAD_TIME_PO: "HISTORIC_LEAD_TIME_PO",
  PURCHASE_ORDER_ASSIGNABLE_USERS: "PURCHASE_ORDER_ASSIGNABLE_USERS",
  PURCHASE_ORDER_TIMELINE: "PURCHASE_ORDER_TIMELINE",

  /** ======== Purchase Order ======== */
  CUSTOMER_NAMES: "CUSTOMER_NAMES",

  /** ======== Inbound Deliveries ======== */
  INBOUND_DELIVERY: "INBOUND_DELIVERY",
  INBOUND_DELIVERIES: "INBOUND_DELIVERIES",
  INBOUND_DELIVERY_TRADE_LANE: "INBOUND_DELIVERY_TRADE_LANE",
  INBOUND_DELIVERY_TRACKING: "INBOUND_DELIVERY_TRACKING",
  INBOUND_DELIVERY_COMMUNICATION_SUMMARY: "INBOUND_DELIVERY_COMMUNICATION_SUMMARY",
  INBOUND_DELIVERY_EMAILS: "INBOUND_DELIVERY_EMAILS",
  INBOUND_DELIVERY_LOGISTICS_PROVIDERS: "INBOUND_DELIVERY_LOGISTICS_PROVIDERS",
  INBOUND_DELIVERY_ASSIGNABLE_USERS: "INBOUND_DELIVERY_ASSIGNABLE_USERS",

  PURCHASE_CONTROL_TOWER: "PURCHASE_CONTROL_TOWER",
};
